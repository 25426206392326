
import { defineComponent, ref, reactive, UnwrapRef, onMounted, PropType, h, watch } from 'vue'
import { FileInfo, FileResponse } from '@/API/types'

import { AutoTableColProps, AutoTableCellProps, RowCellProps, APIDataProps } from '@/types'
import { expandProps } from "@/views/Budget/types"
import deepClone from '@/utils/deepClone'
import { useStore } from 'vuex'
import { tableHeader } from '@/views/Budget/data'
import { message } from "ant-design-vue"
import { fnChildren } from '@/utils/index'
import { baseURL } from '@/API'
import { getBatchList, findComments, setComments, findFinancingBatchList } from '@/API/approve'
import useTableHeight from "@/hooks/useTableHeight";
import blobDownload from '@/utils/blobDownload';
import axios from 'axios'
import { getSubscribedBu } from "@/API/approve"
import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
interface DataProps {
    id: string;
    key: string;
    approvalBatch: string;
    programCode: string;
    programName: string;
    fromDate: string;
    toDate: string;
    status: string;
    signedProgramUploaded: boolean;
    approvalDate: string;
}

const columns = [
        {
            title: 'Approval Batch (Same as BMBS)',
            dataIndex: 'batchName',
            key: 'approvalBatch',
            width: 240,
            fixed: 'left'
        },
        {
            title: 'NO.',
            dataIndex: 'NO',
            key: 'NO',
            slots: { title: 'customTitle', customRender: 'no' },
            width: 80,
            align: 'left'
        },
       
        {
            title: 'Program Code',
            dataIndex: 'programCode',
            key: 'programCode',
            width: 150,
            align: 'left',
            ellipsis: true,
        },
        {
            title: 'Program Name',
            dataIndex: 'programName',
            key: 'programName',
            width: 200,
            slots: { customRender: 'programName' },
            align: 'left',
            ellipsis: true,
        },
        {
            title: 'From_date',
            dataIndex: 'from',
            key: 'fromDate',
            width: 180,
            align: 'center'
        },
        {
            title: 'To_date',
            dataIndex: 'to',
            key: 'toDate',
            width: 180,
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            align: 'center'
        },
        {
            title: 'Signed Program Uploaded',
            dataIndex: 'signedProgramUploaded',
            key: 'signedProgramUploaded',
            slots: { customRender: 'programUploaded' },
            width: 220,
            align: 'center'
        },
        {
            title: 'Approval Date',
            dataIndex: 'approvalDate',
            key: 'approvalDate',
            width: 120,
            align: 'center'
        }
    ];
export default defineComponent({
    components: {
    },
    setup () {
        const store = useStore()
        const { tableHeight } = useTableHeight(false)
        const typeP = ref<string>(store.state.user.usrnm)
        const uploadData = ref({})
        // Dealer Type
        const isActive = ref<number>(0)
        // Batch
        const isActiveBtn = ref<number>(0)
        // 导航的数据
        const dataSource = ref<DataProps[]>([])
        const buUnit = ref()

        const programCode = ref('')
        // select table弹窗是否显示
        const selectVisible = ref(false)
        const subsidyRate = ref('')
        const customerRate = ref('')

        // sined upload
        const visibleUpload = ref(false)
        const fileList = ref()
        const headers = {
          Authorization: store.state.Authorization
        }

        // 验证需要的参数
        const vilidateObj = ref()
        // 表格的表头
        const cols = ref<AutoTableColProps[]>([])
        // 上传Batch列表的弹窗验证
        const visibleVilidate = ref(false)

        // comments取消
        const handleCancel = () => {
            selectVisible.value = false
        }

        // 配置导航数据
        const navArr = ref<{navName: string; badge: number}[]>([])
        const configNavArr = ((data: any) => {
            navArr.value = []
            data.forEach((item: any) => {
                navArr.value.push({
                    navName: item.buName,
                    badge: item.total
                })
            })
        })

        // 配置batch按钮的数据 初始化的时候MB 
        const batchBtnArr = ref<string[]>([])
        const configBatchArr = ((data: any) => {
            batchBtnArr.value = []
            // console.log(d);
            data.forEach((item: any) => {
                return batchBtnArr.value.push(item.batchName)
            })
        })
        
        const list = ref()
        // 切换bu时 重新获取batch的数据
        const handleActive = (index: number) => {
            isActive.value = index
            isActiveBtn.value = 0
            configBatchArr(list.value[isActive.value].batchApprovalDtoList)
            dataSource.value = list.value[isActive.value].batchApprovalDtoList[isActiveBtn.value].programFinacingStatusList
            vilidateObj.value = list.value[isActive.value].batchApprovalDtoList[isActiveBtn.value]
            
        }

        // 切换batch
        const handleBatch = (index: number) => {
            isActiveBtn.value = index
            dataSource.value = list.value[isActive.value].batchApprovalDtoList[isActiveBtn.value].programFinacingStatusList
            vilidateObj.value = list.value[isActive.value].batchApprovalDtoList[isActiveBtn.value]
        }

        // 下载需要审批的文件
        const programFileDownload = () => {
            // const params = ['f607d973-f4b3-4ef2-b30f-114bb0ceb20a']
            const programId = list.value[isActive.value].batchApprovalDtoList[isActiveBtn.value].programFinacingStatusList.map((item: any) => item.programId)
            const params = {
                programId,
                bu: list.value[isActive.value].buName,
                user: store.state.user.usrnm,
                batch: batchBtnArr.value[isActiveBtn.value]
            }
            const config = { 
                url: `/pgapi/program_approval/downloadPdf`,
                method: 'post',
                params
            }
            blobDownload(config)
        }

        // Comments抽屉打开
        const handleCommentSetting = (tableId: string) => {
            selectVisible.value = true
            const params = {
                bu: list.value[isActive.value].buName,
                batch: batchBtnArr.value[isActiveBtn.value]
            }
            subsidyRate.value = ''
            customerRate.value = ''
            findComments({ params: params}).then(res => {
                subsidyRate.value = res[0].comment
                customerRate.value = res[1].comment
            })
        }

        // 关闭Comments
        const handleSetComments = () => {
            const formData = new FormData()
            formData.append('subsidyComment', subsidyRate.value)
            formData.append('customerComment', customerRate.value)
            formData.append('bu', list.value[isActive.value].buName)
            formData.append('batch', batchBtnArr.value[isActiveBtn.value])
            setComments(formData).then(res => {
                selectVisible.value = false
                message.success('Save successed!')
            })
            
        }
        
        // 列表数据
        const getData = () => {
            isActive.value = 0
            isActiveBtn.value = 0
            getBatchList(typeP.value).then(res => {
                if (res.length > 0) {
                    configNavArr(res)
                    configBatchArr(res[0].batchApprovalDtoList)
                    list.value = res
                    dataSource.value = res[0].batchApprovalDtoList[0].programFinacingStatusList
                    vilidateObj.value = res[0].batchApprovalDtoList[0]
                }
                
            })
        }
        const handleCloseUpload = () => {
            visibleUpload.value = false
            getData()
        }

        // 搜索
        const onSearch = (searchValue: string) => {
            const params = {
                bu: buUnit.value,
                code: searchValue
            }
            if (!searchValue && !buUnit.value) {
                message.error("请选择Business Unit和Approved Program Code")
                return false
            }
            findFinancingBatchList({params}).then(res => {
                if (res.length > 0) {
                    // configNavArr(res)
                    // dataSource.value = res[0].batchApprovalDtoList[0].programFinacingStatusList
                    configNavArr(res)
                    configBatchArr(res[0].batchApprovalDtoList)
                    list.value = res
                    dataSource.value = res[0].batchApprovalDtoList[0].programFinacingStatusList
                    vilidateObj.value = res[0].batchApprovalDtoList[0]
                } else {
                    navArr.value = []
                    batchBtnArr.value = []
                    dataSource.value = []
                    vilidateObj.value = []
                }
            })
        }
        // 上传成功，失败的回调
        const uploadChange = (info: FileInfo) => {
            // 上传成功。。
            if (info.file.status === 'done') {
                if ((info.file.response as FileResponse).code === '0') { 
                    message.success(`${info.file.name} file uploaded successfully`);
                } else {
                    message.error(`${info.file.name} file uploaded failed`);
                }
                getData()
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file uploaded failed`);
                setTimeout(() => {
                    fileList.value = []
                }, 100)
            }
          
        }
        // 上传之后，组装上传参数
        const beforeUpload = (file: any) => {
            const arr = list.value[isActive.value].batchApprovalDtoList[isActiveBtn.value].programFinacingStatusList.map((item: any) => item.programId)
            uploadData.value = {
                programIds: JSON.stringify(arr),
                bu: list.value[isActive.value].buName,
                batch: batchBtnArr.value[isActiveBtn.value],
                user: store.state.user.usrnm
            }
            return true
        }
        const handleVilidateModel = () => {
            visibleVilidate.value = true
        }
        
        
        const buSelectData = ref<BuItem[]>([])
        // 获取business unit
        const getBuUnit = () => {
            getSubscribedBu().then(res => {
                buSelectData.value = res
            })
        }
        
        onMounted(() => {
            getBuUnit()
            getData()
        })
        
        return {
            tableHeight,
            programCode,
            navArr,
            batchBtnArr,
            list,
            dataSource,
            selectVisible,
            handleSetComments,
            
            visibleUpload,
            fileList,
            headers,

            columns,

            isActive,
            isActiveBtn,
            handleActive,
            handleBatch,
            onSearch,
            handleCommentSetting,
            handleCancel,
            subsidyRate,
            customerRate,
            programFileDownload,
            uploadChange,
            visibleVilidate,
            handleVilidateModel,
            vilidateObj,
            uploadData,
            beforeUpload,
            handleCloseUpload,
            baseURL,
            buSelectData,
            buUnit
        }
    }
})
